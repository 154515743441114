* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

img {
  border: none;
}

button {
  outline: none !important;
  border: 0;
}

@font-face {
  font-family: "drukwideweb";
  src: url("./assets/fonts/drukwideweb-super/drukwideweb-super.eot");
  src: url("./assets/fonts/drukwideweb-super/drukwideweb-super.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/drukwideweb-super/drukwideweb-super.woff2")
      format("woff2"),
    url("./assets/fonts/drukwideweb-super/drukwideweb-super.woff")
      format("woff"),
    url("./assets/fonts/drukwideweb-super/drukwideweb-super.ttf")
      format("truetype"),
    url("./assets/fonts/drukwideweb-super/drukwideweb-super.svg#drukwideweb-super")
      format("svg");
}

/* @font-face {
  font-family: "helvetica-neue-lt-geo-roman";
  src: url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.eot");
  src: url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff2")
      format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.woff")
      format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.ttf")
      format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-roman/helvetica-neue-lt-geo-roman.svg#helvetica-neue-lt-geo-roman")
      format("svg");
}

@font-face {
  font-family: "helvetica-neue-lt-geo-bold";
  src: url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.eot");
  src: url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff2")
      format("woff2"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.woff")
      format("woff"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.ttf")
      format("truetype"),
    url("./assets/fonts/helvetica-neue-lt-geo-bold/helvetica-neue-lt-geo-bold.svg#helvetica-neue-lt-geo-bold")
      format("svg");
} */

/* Roboto */
@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
}

/* Monserrat */
@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
}

/* FiraGo */
@font-face {
  font-family: "FiraGo";
  src: url("./assets/fonts/FiraGo/FiraGO-Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "FiraGo";
  src: url("./assets/fonts/FiraGo/FiraGO-Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "FiraGo";
  src: url("./assets/fonts/FiraGo/FiraGO-Bold.otf") format("opentype");
  font-weight: bold;
}

#root {
  height: 100%;
  margin: 0;
  overflow: hidden;
  touch-action: pan-x pan-y;
}

/* @media only screen and (max-height: 667px) and (max-width: 375px) {
  #root {
    overflow: visible;
  }
} */

html {
  height: 100%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 992px) and (orientation: landscape) {
  html {
    /* transform: rotate(-90deg); */
    transform-origin: left top;
    width: 100vh;
    height: 100vw;

    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}

body {
  height: 100%;
  margin: 0;
  overflow: hidden;
  background: #000000;
  min-height: 100vh;
  /* font-family: "FiraGo", "Roboto", "Montserrat", sans-serif; */
}

/* @media only screen and (max-height: 668px) and (max-width: 378px) {
  body {
    overflow: visible;
  }
} */

body iframe {
  display: none;
}

.container,
.container-sm {
  width: 100%;
  box-sizing: border-box;
  padding: 0 8px;
}

a {
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  color: #ffffff;
}

a,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: #ffffff;
}

a:focus {
  outline: none;
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.clearfix:before,
.clearfix:after {
  display: block;
  content: "";
  clear: both;
}

.roboto-font {
  font-family: "Roboto", sans-serif;
}

#root,
html,
iframe,
body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
/* @media only screen and (max-height: 667px) and (max-width: 375px) {
  html, body,
  #root {
    overflow-y: visible!important;
    overflow-x: hidden;
    width: 100vw;
  }
} */

@media (max-width: 992px) {
  html {
      touch-action: pan-x pan-y !important;
  }
}

input{ touch-action: none; }
input[type='text'],input[type='number'],textarea {font-size:16px;}
input:focus { font-size: 16px!important}